import React, { useContext } from 'react'
import { Link } from 'gatsby'
import github from '../img/github-icon.svg'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  componentDidMount() {}

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'show',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-lg">
          <Link
            to="/"
            className="navbar-brand d-lg-none"
            title="Reno Collective"
          >
            Reno Collective
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => this.toggleHamburger()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${this.state.navBarActiveClass}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav justify-content-end w-100">
              <li className="nav-item">
                <Link to="/community" className="nav-link" title="RC Community">
                  👩🏻‍💻 community
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link to="/join" className="nav-link" title="Join">
                  ⚡️ membership
                </Link>
              </li>
            </ul>

            <Link
              to="/"
              className="navbar-brand d-none d-lg-block px-lg-6"
              title="Reno Collective"
            >
              Reno Collective
            </Link>

            <ul className="navbar-nav justify-content-start w-100">
              <li className="nav-item dropdown">
                <Link to="/space" className="nav-link" title="The Space">
                  👨🏼‍🚀 &nbsp;space
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link to="/perks" className="nav-link" title="FAQs">
                  🦄 &nbsp;perks
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link" title="Blog">
                  🎉 &nbsp;blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
